import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Atf from "components/atf"
import Section from "components/section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Atf heading="404" />
    <Section>
      <p>
        404 - Nichts gefunden
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
